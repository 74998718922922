(function() {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('VideoEvent', VideoEvent);

    VideoEvent.$inject = ['$resource', 'DateUtils'];

    function VideoEvent ($resource, DateUtils) {
        var resourceUrl =  'api/sessions/:sessionId/events/video';

        return $resource(resourceUrl, {}, {
            'isVideoRoomActive':{method: 'GET'},
            transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        });
    }
})();
