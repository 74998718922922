(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('session-detail', {
            parent: 'entity',
            url: '/s/{id}',
            data: {
                pageTitle: 'superChannelApp.session.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/session/session-detail.html',
                    controller: 'SessionDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('session');
                    $translatePartialLoader.addPart('item');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Session', '$state', function($stateParams, Session, $state) {
                    return Session.get({id : $stateParams.id}).$promise.catch(function (response) {
                        if (response.data && response.data.fieldErrors && response.data.fieldErrors[0].message == "expired") {
                            $state.go('expired',  {tenant: $stateParams.tenant});
                        } else {
                            $state.go('error', {tenant: $stateParams.tenant});
                        }
                    });
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: 'session',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }

})();
