(function() {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('Event', Event);

    Event.$inject = ['$resource', 'DateUtils'];

    function Event ($resource, DateUtils) {
        var resourceUrl =  'api/sessions/:sessionId/events/:id';

        return $resource(resourceUrl, {}, {});
    }
})();
