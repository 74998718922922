(function () {
    /*jshint bitwise: false*/
    'use strict';

    angular.module('superChannelApp')
        .factory('Geolocation', Geolocation);

    Geolocation.$inject = ['$rootScope', '$q'];

    function Geolocation($rootScope, $q) {
        var watchID, timerID;

        return {
            stopWatchPosition: stopWatchPosition,
            getAccurateLocation: getAccurateLocation
        };

        function isSupported() {
            return 'geolocation' in navigator;
        }

        function stopWatchPosition() {
            clearTimeout(timerID);
            navigator.geolocation.clearWatch(watchID);
        }

        function getAccurateLocation(options) {
            var deferred = $q.defer(),
                lastCheckedPosition;
            options = options || {};
            if (!isSupported()) {
                deferred.reject({error: "notSupported"});
            } else {
                if (!options.maxWait) {
                    options.maxWait = 20000;
                }
                if (!options.desiredAccuracy) {
                    options.desiredAccuracy = 30;
                }
                if (!options.timeout) {
                    options.timeout = options.maxWait;
                }

                options.maximumAge = 0;
                options.enableHighAccuracy = true;

                navigator.geolocation.getCurrentPosition(foundCurrentPosition, startWatchPosition, options);
            }
            return deferred.promise;

            function foundCurrentPosition(position) {
                lastCheckedPosition = position;
                if (position.coords.accuracy <= options.desiredAccuracy) {
                    foundPosition(position);
                } else {
                    startWatchPosition();
                }
            }

            function startWatchPosition() {
                options.desiredAccuracy = 40;
                watchID = navigator.geolocation.watchPosition(checkLocation, onError, options);
                timerID = setTimeout(stopTrying, options.maxWait);
            }

            function checkLocation(position) {
                lastCheckedPosition = position;
                if (position.coords.accuracy <= options.desiredAccuracy) {
                    clearTimeout(timerID);
                    navigator.geolocation.clearWatch(watchID);
                    foundPosition(position);
                }
            }

            function onError() {
                clearTimeout(timerID);
                stopTrying();
            }

            function stopTrying() {
                navigator.geolocation.clearWatch(watchID);
                if (!lastCheckedPosition) {
                    $rootScope.$apply(function () {
                        deferred.reject({error: "notFound"});
                    });
                }
                foundPosition(lastCheckedPosition);
            }

            function foundPosition(position) {
                $rootScope.$apply(function () {
                    deferred.resolve(position);
                });
            }
        }
    }
})();
