"use strict";

(function () {
    "use strict";

    angular.module("superChannelApp").service("ConnectParticipant", ConnectParticipant);
    ConnectParticipant.$inject = ['$rootScope'];

    function ConnectParticipant($rootScope) {
        this.connecting = connecting;
        this.disconnectRoom = disconnectRoom;
        var activeRoom;
        var Video = Twilio.Video;
        var flip = document.getElementById('CameraFlip');
        var select = document.getElementById('video-devices');
        select.innerHTML = '';
        var facingMode = 'environment';
        flip.addEventListener('click', cameraFlip);
        select.addEventListener('change', updateVideoDevice);

        function connecting(token) {
            navigator.mediaDevices.getUserMedia({video: true}).then(function () {
                navigator.mediaDevices.enumerateDevices().then(gotDevices);
            });
            Video.createLocalTracks({
                audio: false,
                video: {
                    width: 300,
                    facingMode: facingMode
                }
            }).then(function (localTracks) {
                return Video.connect(token, {
                    tracks: localTracks
                }).then(function (room) {
                    window.room = activeRoom = room;
                    // console.log("Successfully joined a Room: ".concat(room.name));
                    localTracks.forEach(function (track) {
                        document.getElementById("local-media-div").appendChild(track.attach());
                    });

                    room.on('participantDisconnected', function () {
                        document.getElementById("local-media-div").innerHTML = null;
                        disconnectRoom();
                        $rootScope.liveVideoActive = false;
                    });
                    room.on("participantConnected", function (participant) {
                        // console.log('Participant "'.concat(participant.identity, '" connected'));
                        participant.tracks.forEach(function (publication) {
                            if (publication.isSubscribed) {
                                var track = publication.track;
                                document.getElementById("local-media-div").appendChild(track.attach());
                            }
                        });
                        participant.on("trackSubscribed", function (track) {
                            document.getElementById("local-media-div").appendChild(track.attach());
                        });
                    }, function (error) {
                        console.error("Unable to connect to Room: ".concat(error.message));
                    });
                });
            }).catch(function (error) {
                console.error("Caught error while connecting to room", error);
            });
        }

        function gotDevices(mediaDevices) {
            select.innerHTML = '';
            $rootScope.cameraCount = 0;
            select.appendChild(document.createElement('option'));
            var count = 1;
            mediaDevices.forEach(function (mediaDevice) {
                if (mediaDevice.kind === 'videoinput') {
                    $rootScope.cameraCount++;
                    var option = document.createElement('option');
                    option.value = mediaDevice.deviceId;
                    var label = mediaDevice.label || "Camera ".concat(count++);
                    var textNode = document.createTextNode(label);
                    option.appendChild(textNode);
                    select.appendChild(option);
                }
            });
        }

        function updateVideoDevice(event) {
            var selectedDevice = event.target;
            var localParticipant = room.localParticipant;
            var tracksToUnpublish = Array.from(localParticipant.videoTracks.values()).map(
                function (trackPublication) {
                    return trackPublication.track;
                }
            )
            localParticipant.unpublishTracks(tracksToUnpublish);

            if (selectedDevice.value !== '') {
                Video.createLocalVideoTrack({
                    deviceId: {exact: selectedDevice.value},
                    width: 300
                }).then(function (localVideoTrack) {
                    document.getElementById("local-media-div").innerHTML = null;
                    localParticipant.publishTrack(localVideoTrack);
                    [localVideoTrack].forEach(function (track) {
                        document.getElementById("local-media-div").appendChild(track.attach());
                    });
                });
            }
        }

        function applyVideoInputDeviceSelection(facingMode) {
            var localParticipant = room.localParticipant;

            var tracksToUnpublish = Array.from(localParticipant.videoTracks.values()).map(
                function (trackPublication) {
                    return trackPublication.track;
                }
            )
            localParticipant.unpublishTracks(tracksToUnpublish);

            Video.createLocalVideoTrack({
                width: 300,
                facingMode: facingMode
            }).then(function (localVideoTrack) {
                document.getElementById("local-media-div").innerHTML = null;
                localParticipant.publishTrack(localVideoTrack);
                [localVideoTrack].forEach(function (track) {
                    document.getElementById("local-media-div").appendChild(track.attach());
                });
            });
        }

        function stopMediaTracks(stream) {
            stream.getTracks().forEach(function (track) {
                track.stop();
            });
        }

        function disconnectRoom() {
            document.getElementById("local-media-div").innerHTML = null;
            activeRoom.localParticipant.tracks.forEach(function (publication) {
                var attachedElements = publication.track.detach();
                attachedElements.forEach(function (element) {
                    return element.remove();
                });
            }); // To disconnect from a Room

            activeRoom.disconnect();
            select.removeEventListener('change', updateVideoDevice);
        }

        function cameraFlip() {
            facingMode === 'environment' ? facingMode = 'user' : facingMode = 'environment';
            applyVideoInputDeviceSelection(facingMode);
        }
    }
})();
