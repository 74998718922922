(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('tenantInterceptor', tenantInterceptor);

    tenantInterceptor.$inject = ['$rootScope', '$q', '$location'];

    function tenantInterceptor($rootScope, $q, $location) {
        var service = {
            request: request
        };

        return service;

        function request(config) {
            function extractTenantId() {
                var url = $location.url();
                if (url.indexOf('/') < 0)
                    return null;
                else
                    return url.split('/')[1];
                // var host = $location.host();
                // if (host.indexOf('.') < 0)
                //     return null;
                // else
                //     return host.split('.')[0];
            }

            /*jshint camelcase: false */
            var tenant = extractTenantId();
            if (tenant) {
                config.headers.TenantID = tenant;
            }
            return config;
        }
    }
})();
