(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('LocationErrorController', LoginController);

    LoginController.$inject = ['$window'];

    function LoginController($window) {
        var vm = this;

        vm.refresh = refresh;

        function refresh() {
            $window.location.reload();
        }
    }
})();
