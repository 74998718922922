(function () {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('VideoInitiate', VideoInitiate);

    VideoInitiate.$inject = ['$resource'];

    function VideoInitiate($resource) {
        var resourceUrl = 'api/sessions/:sessionId/video';

        return $resource(resourceUrl, {}, {
            getVideoResource: {
                method: 'GET',
                isArray: true,
//                url: resourceUrl + '/video'
                transformResponse: function (data) {
                    if (data) {
                        data = data.split(",");
                    }
                    return data;
                }
            }
        });
    }
})();
