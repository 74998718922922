(function () {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('Item', Item);

    Item.$inject = ['$resource', 'DateUtils'];

    function Item($resource, DateUtils) {
        var resourceUrl = 'api/sessions/:sessionId/items/:itemId';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                    }
                    return data;
                }
            },
            'updates': {method: 'GET', isArray: true},
            'update': {method: 'PUT'}
        });
    }
})();
