(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$translateProvider'];

    function stateConfig($stateProvider, $translateProvider) {
        $stateProvider
            .state('error', {
                parent: 'app',
                url: '/error',
                data: {
                    authorities: [],
                    pageTitle: 'error.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/layouts/error/error.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', 'JhiLanguageService',
                        function ($translate, JhiLanguageService) {
                            return JhiLanguageService.getCurrent().then(function (language) {
                                if (language === $translateProvider.preferredLanguage()) {
                                    return $translate.refresh();
                                }
                            });
                        }
                    ]
                }
            })
            .state('expired', {
                parent: 'app',
                url: '/expired',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/layouts/error/expired.html'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', 'JhiLanguageService',
                        function ($translate, JhiLanguageService) {
                            return JhiLanguageService.getCurrent().then(function (language) {
                                if (language === $translateProvider.preferredLanguage()) {
                                    return $translate.refresh();
                                }
                            });
                        }
                    ]
                }
            });
    }
})();
