(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('apiUrlInterceptor', apiUrlInterceptor);

    apiUrlInterceptor.$inject = ['API_URL'];

    function apiUrlInterceptor (API_URL) {
        var service = {
            request: request
        };

        return service;

        function request (config) {
            /*jshint camelcase: false */
            if (config.url.match("^api/")) {
                config.url = API_URL + config.url;
            }

            return config;
        }
    }
})();
