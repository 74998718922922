(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .controller('ItemDialogController', ItemDialogController);

    ItemDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Item', 'AlertService', 'DataUtils', 'FileRestrictions'];

    function ItemDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, Item, AlertService, DataUtils, FileRestrictions) {
        var BYTES_IN_MB = 1048576;
        var vm = this;
        var alertError = [];

        var maxFileSizeInMB = FileRestrictions.getMaxFileSize();
        var documentFileTypesArray = FileRestrictions.getDocumentFileTypes();
        var photoFileTypesArray = FileRestrictions.getPhotoFileTypes();

        documentFileTypesArray.$promise.then(function (types) {
            vm.documentExtensions = types.join(',');
        });
        photoFileTypesArray.$promise.then(function (types) {
            vm.photoExtensions = types.join(',');
        });

        var validUrlPattern = new RegExp(
            "^" +
            // protocol identifier
            "(?:(?:https?|ftp)://)" +
            // user:pass authentication
            "(?:\\S+(?::\\S*)?@)?" +
            "(?:" +
            // IP address exclusion
            // private & local networks
            "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
            "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
            "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
            // IP address dotted notation octets
            // excludes loopback network 0.0.0.0
            // excludes reserved space >= 224.0.0.0
            // excludes network & broacast addresses
            // (first & last IP address of each class)
            "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
            "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
            "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
            "|" +
            // host name
            "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
            // domain name
            "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
            // TLD identifier
            "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
            // TLD may end with dot
            "\\.?" +
            ")" +
            // port number
            "(?::\\d{2,5})?" +
            // resource path
            "(?:[/?#]\\S*)?" +
            "$", "i"
        );

        vm.item = entity;
        vm.sessionId = $stateParams.id;
        vm.clear = clear;
        vm.save = save;
        vm.validUrlPattern = validUrlPattern;

        $timeout(function () {
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            var error = false;
            for (var i = 0; i < alertError.length; i++) {
                AlertService.closeAlert(alertError[i].id);
            }
            alertError = [];
            vm.isSaving = true;
            if (vm.item.type === "URL") {
                if (!validUrlPattern.test(vm.item.content.url)) {
                    alertError.push(AlertService.error("superChannelApp.item.url.help.url"));
                    error = true;
                }
            }
            var file = vm.item.content.binaryData;
            if (file) {
                var MAX_FILE_SIZE = maxFileSizeInMB.max * BYTES_IN_MB;
                if (DataUtils.byteSize(file) > MAX_FILE_SIZE) {
                    alertError.push(AlertService.error("error.maxFileSize", {maxSize: MAX_FILE_SIZE / 1048576}));
                    error = true;
                }
                if (vm.item.type === "PHOTO") {
                    if (photoFileTypesArray.indexOf((DataUtils.getDataType(file))) < 0) {
                        alertError.push(AlertService.error("error.invalidFormat"));
                        error = true;
                    }
                }
                if (vm.item.type === "DOCUMENT") {
                    if (documentFileTypesArray.indexOf((DataUtils.getDataType(file))) < 0) {
                        alertError.push(AlertService.error("error.invalidFormat"));
                        error = true;
                    }
                }
            }
            if (!error) {
                Item.save({sessionId: vm.sessionId}, vm.item, onSaveSuccess, onSaveError);
            } else {
                onSaveError();
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

    }
})();
