(function() {
    'use strict';

    angular
        .module('superChannelApp', [
            'pascalprecht.translate',
            'ngResource',
            'ngCacheBuster',
            'ui.router',
            'ui.bootstrap'
            // jhipster-needle-angularjs-add-module JHipster will add new module here
        ])
        .config(['$locationProvider', function ($locationProvider){
            $locationProvider.hashPrefix('');
        }])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler'];

    function run(stateHandler, translationHandler) {
        stateHandler.initialize();
        translationHandler.initialize();
    }
})();
