(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('404', {
                parent: 'app',
                url: '/404',
                data: {
                    authorities: []
                },
                views: {
                    'content@': {
                        templateUrl: 'app/layouts/404/404.html'
                    }
                },
            })
    }
})();
