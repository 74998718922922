(function () {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('Session', Session);

    Session.$inject = ['$resource', 'DateUtils'];

    function Session($resource, DateUtils) {
        var resourceUrl = 'api/sessions/:id';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.start = DateUtils.convertDateTimeFromServer(data.start);
                        data.end = DateUtils.convertDateTimeFromServer(data.end);
                    }
                    return data;
                }
            },
            'expiration': {
                method: 'POST',
                url: resourceUrl + '/expiration'
            },
            'updates': {
                method: 'GET',
                url: resourceUrl + '/updates'
            }
        });
    }
})();
