(function() {
    'use strict';
    angular
        .module('superChannelApp')
        .factory('FileRestrictions', FileRestrictions);

    FileRestrictions.$inject = ['$resource'];

    function FileRestrictions ($resource) {
        var resourceUrl = 'api/fileRestrictions/:restrictionType';

        return $resource(resourceUrl, {}, {
            getPhotoFileExtensions: {
                method: 'GET',
                isArray: true,
                params: {restrictionType: 'photoFileExtensions'},
                transformResponse: function (data) {
                    if (data) {
                        data = data.split(",");
                    }
                    return data;
                }
            },
            getPhotoFileTypes: {
                method: 'GET',
                isArray: true,
                params: {restrictionType: 'photoFileTypes'},
                transformResponse: function (data) {
                    if (data) {
                        data = data.split(",");
                    }
                    return data;
                }
            },
            getDocumentFileExtensions: {
                method: 'GET',
                isArray: true,
                params: {restrictionType: 'documentFileExtensions'},
                transformResponse: function (data) {
                    if (data) {
                        data = data.split(",");
                    }
                    return data;
                }
            },
            getDocumentFileTypes: {
                method: 'GET',
                isArray: true,
                params: {restrictionType: 'documentFileTypes'},
                transformResponse: function (data) {
                    if (data) {
                        data = data.split(",");
                    }
                    return data;
                }
            },
            getMaxFileSize: {
                method: 'GET',
                isArray: false,
                params: {restrictionType: 'maxFileSize'},
                transformResponse: function (data) {
                    if (data) {
                        data = {max: parseInt(data, 10)};
                    }
                    return data;
                }
            }
        });
    }
})();
