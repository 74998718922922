(function () {
    'use strict';

    angular.module('superChannelApp').controller('SessionDetailController', SessionDetailController);
    SessionDetailController.$inject = ['$scope', '$rootScope', '$interval', '$window', '$translate', '$state', '$uibModal', 'entity', 'Item', 'Event', 'AlertService', 'DataUtils', 'Geolocation', 'JhiLanguageService', 'GOOGLE_STATIC_MAPS_API', 'FileRestrictions', 'VERSION', 'VideoInitiate', 'ConnectParticipant','VideoEvent', '$q'];

    function SessionDetailController($scope, $rootScope, $interval, $window, $translate, $state, $uibModal,
                                     entity, Item, Event, AlertService, DataUtils, Geolocation, JhiLanguageService,
                                     GOOGLE_STATIC_MAPS_API, FileRestrictions, VERSION, VideoInitiate, ConnectParticipant, VideoEvent) {
        console.log("SuperChannel Version: " + VERSION);
        var vm = this;
        var alertError = [];
        var POOLING_TIME = 5000;
        var BYTES_IN_MB = 1048576;
        var lastItem;
        var maxFileSizeInMB = FileRestrictions.getMaxFileSize();
        var documentFileTypesArray = FileRestrictions.getDocumentFileTypes();
        var photoFileTypesArray = FileRestrictions.getPhotoFileTypes();
        var roomToken;
        var dataURL;
        documentFileTypesArray.$promise.then(function (types) {
            vm.documentExtensions = types.join(',');
        });
        photoFileTypesArray.$promise.then(function (types) {
            vm.photoExtensions = types.join(',');
        });
        vm.session = entity;
        vm.itemsNotRead = 0;
        vm.itemCreationButtons = generateChannelButtons();
        vm.inboxItems = generateInboxItems(vm.session.items);
        vm.itemCreation = itemCreation;
        vm.openItem = openItem;
        vm.validateItem = validateItem;
        vm.sketchPhoto = sketchPhoto;
        vm.captureSketchPhoto = captureSketchPhoto;
        vm.sketchPhotoContent = '';
        vm.cleanView = cleanView;
        vm.blackPen = blackPen;
        vm.redPen = redPen;
        vm.clearPad = clearPad;
        vm.isEmpty = isEmpty;
        vm.isBlack = true;
        vm.isRed = false;
        lastItem = vm.inboxItems.slice().pop();
        vm.updateInbox = $interval(updateInbox, POOLING_TIME);
        vm.isVideoRoomActive = $interval(checkForVideoRoom, POOLING_TIME);
        vm.inboxPopover = {
            templateUrl: 'app/layouts/inbox/inbox.html'
        };
        checkClientLang();

        function checkClientLang() {
            var useDefaultLang = true;
            JhiLanguageService.getCurrent().then(function (language) {
                JhiLanguageService.getAll().then(function (languages) {
                    for (var i = 0; i < languages.length; i++) {
                        if (language === languages[i]) {
                            useDefaultLang = false;
                        }
                    }

                    if (useDefaultLang) {
                        $translate.use(vm.session.tenantLang);
                    } else {
                        $translate.use(language);
                    }
                });
            });
        }

        if (!$window.performance || !$window.performance.navigation || $window.performance.navigation.type !== 1) {
            Event.save({
                sessionId: vm.session.id
            }, {
                code: 101,
                params: {
                    USER_AGENT: navigator.userAgent
                }
            }, function () {
            }, errorCallback);
        }

        var validUrlPattern = new RegExp("^" + // protocol identifier
            "(?:(?:https?|ftp)://)" + // user:pass authentication
            "(?:\\S+(?::\\S*)?@)?" + "(?:" + // IP address exclusion
            // private & local networks
            "(?!(?:10|127)(?:\\.\\d{1,3}){3})" + "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" + "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" + // IP address dotted notation octets
            // excludes loopback network 0.0.0.0
            // excludes reserved space >= 224.0.0.0
            // excludes network & broacast addresses
            // (first & last IP address of each class)
            "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" + "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" + "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" + "|" + // host name
            "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" + // domain name
            "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" + // TLD identifier
            "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" + // TLD may end with dot
            "\\.?" + ")" + // port number
            "(?::\\d{2,5})?" + // resource path
            "(?:[/?#]\\S*)?" + "$", "i");

        var canvas;
        var signaturePad;

        function initPad() {
            canvas = document.querySelector("canvas");
            if (signaturePad) {
                blackPen();
                clearPad();
            } else {
                signaturePad = new SignaturePad(canvas, {
                    backgroundColor: 'rgb(255, 255, 255)'
                });
            }
        }

        function blackPen() {
            vm.isBlack = true;
            vm.isRed = false;
            signaturePad.penColor = "rgb(0, 0, 0)";
        }

        function redPen() {
            vm.isBlack = false;
            vm.isRed = true;
            signaturePad.penColor = "rgb(255, 0, 0)";
        }

        function clearPad() {
            signaturePad.clear();
            document.getElementById("captureSketchPhoto").value = '';
        }

        function isEmpty() {
            if (signaturePad) {
                return signaturePad.isEmpty();
            } else {
                return true;
            }
        }

        function resetPad() {
            clearPad();
            blackPen();
            vm.showSketch = false;
        }

        function buildUrlForLocationItem(item) {
            item.content.url = 'https://www.google.com/maps?q=' + item.content.latitude + ',' + item.content.longitude;
        }

        function generateChannelButtons() {
            var channelButtons = [];

            for (var i = 0; i < vm.session.channels.length; i++) {
                var button;

                switch (vm.session.channels[i].itemContentType) {
                    case "PHOTO":
                        button = {
                            state: "session-detail.new-photo-item",
                            badge: "glyphicon glyphicon-picture"
                        };
                        break;
                    case "SKETCH":
                        button = {
                            state: "session-detail.new-photo-item",
                            badge: "glyphicon glyphicon-pencil"
                        };
                        break;
                    case "LOCATION":
                        button = {
                            state: "session-detail.new-location-item",
                            badge: "glyphicon glyphicon-map-marker"
                        };
                        break;

                    case "URL":
                        button = {
                            state: "session-detail.new-url-item",
                            badge: "glyphicon glyphicon-link"
                        };
                        break;

                    case "DOCUMENT":
                        button = {
                            state: "session-detail.new-document-item",
                            badge: "glyphicon glyphicon-paperclip"
                        };
                        break;

                    case "VIDEO":
                        button = {
                            state: "session-detail.new-document-item",
                            badge: "glyphicon glyphicon-facetime-video"
                        };
                        break;
                }

                button.channel = vm.session.channels[i];
                channelButtons.push(button);
            }

            return channelButtons;
        }

        function generateInboxItems(items) {
            var inboxItems = [];

            for (var x = 0; x < items.length; x++) {
                var item = items[x];

                switch (item.type) {
                    case "PHOTO":
                        item.badge = "glyphicon glyphicon-picture";
                        break;
                    case "SKETCH":
                        item.badge = "glyphicon glyphicon-pencil";
                        break;
                    case "LOCATION":
                        item.badge = "glyphicon glyphicon-map-marker";
                        buildUrlForLocationItem(item);
                        break;

                    case "URL":
                        item.badge = "glyphicon glyphicon-link";
                        break;

                    case "DOCUMENT":
                        item.badge = "glyphicon glyphicon-paperclip";
                        break;
                }

                if (item.status !== 'ACKNOWLEDGED') {
                    vm.itemsNotRead++;
                }

                inboxItems.push(item);
            }

            return inboxItems;
        }

        function checkForVideoRoom(){
            VideoEvent.isVideoRoomActive({
                sessionId: vm.session.id
            }, videoSuccessCallback, videoErrorCallback);
            function videoSuccessCallback(data) {
                vm.isVideoRoomActive = data.result;
            }
            function videoErrorCallback(error) {
                console.log(error);
            }
        }

        function updateInbox() {
            Item.updates({
                sessionId: vm.session.id,
                last: lastItem ? lastItem.id : null
            }, updateSuccess, errorCallback);

            function updateSuccess(data) {
                var newInboxItems = generateInboxItems(data);
                newInboxItems.forEach(function (element) {
                    vm.inboxItems.push(element);
                });
                lastItem = data.length > 0 ? data.pop() : lastItem;
            }
        }

        function openItem(item) {
            Item.get({
                sessionId: vm.session.id,
                itemId: item.id
            }, itemRetrieved, errorCallback);

            function itemRetrieved() {
                if (item.status !== 'ACKNOWLEDGED') {
                    vm.itemsNotRead--;
                    item.status = 'ACKNOWLEDGED';
                }
            }
        }

        function errorCallback(error) {
            if (error.data &&
                error.data.fieldErrors &&
                error.data.fieldErrors[0].message == 'expired') {
                $state.go('expired');
            }
        }

        function validateItem() {
            vm.sending = true;
            var error = false;

            for (var i = 0; i < alertError.length; i++) {
                AlertService.closeAlert(alertError[i].id);
            }

            alertError = [];

            if (vm.newItem.type == "URL") {
                var urlInput = vm.newItem.content.url;
                if (!urlInput.startsWith("http")) {
                    vm.newItem.content.url = "https://" + urlInput;
                }
                if (!validUrlPattern.test(vm.newItem.content.url)) {
                    alertError.push(AlertService.error("superChannelApp.item.url.help.url"));
                    error = true;
                }
            }
            if (vm.newItem.type === "SKETCH") {
                dataURL = signaturePad.toDataURL("image/jpeg");
                vm.newItem.content = {};
                vm.newItem.content.binaryData = dataURL;
            }

            var file = vm.newItem.content.binaryData;

            if (file) {
                var MAX_FILE_SIZE = maxFileSizeInMB.max * BYTES_IN_MB;

                if (DataUtils.byteSize(file) > MAX_FILE_SIZE) {
                    alertError.push(AlertService.error("error.maxFileSize", {
                        maxSize: MAX_FILE_SIZE / BYTES_IN_MB
                    }));
                    error = true;
                }

                if (vm.newItem.type === "PHOTO") {
                    if (photoFileTypesArray.indexOf(DataUtils.getDataType(file)) < 0) {
                        alertError.push(AlertService.error("error.invalidFormat"));
                        error = true;
                    }
                }

                if (vm.newItem.type === "DOCUMENT") {
                    if (documentFileTypesArray.indexOf(DataUtils.getDataType(file)) < 0) {
                        alertError.push(AlertService.error("error.invalidFormat"));
                        error = true;
                    }
                }
            }

            if (!error) {
                saveItem();
                if (signaturePad) {
                    resetPad();
                }
            } else {
                vm.sending = false;
            }
        }

        function captureSketchPhoto() {
            angular.element('#captureSketchPhoto').trigger('click');
        }

        function fixImgOrientation(imageToFix) {
            loadImage(
                imageToFix,
                function (img) {
                    var ctx = canvas.getContext("2d");
                    var hRatio = canvas.width / img.width;
                    var vRatio = canvas.height / img.height;
                    var ratio = Math.min(hRatio, vRatio);
                    var centerShiftX = (canvas.width - img.width * ratio) / 2;
                    var centerShiftY = (canvas.height - img.height * ratio) / 2;
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0, img.width, img.height,
                        centerShiftX, centerShiftY, img.width * ratio, img.height * ratio);
                    vm.sketchPhotoContent = '';
                }
            );
        }

        function sketchPhoto() {
            if (typeof vm.sketchPhotoContent !== undefined && vm.sketchPhotoContent !== '') {
                fixImgOrientation(vm.sketchPhotoContent);
            }
        }

        function saveItem() {
            Item.save({
                sessionId: vm.session.id
            }, vm.newItem, cleanView, function () {
                vm.sending = false;
            });
        }

        function itemCreation(channel) {
            cleanView();
            vm.showMap = false;
            vm.showSketch = false;
            vm.newItem = {
                type: channel.itemContentType,
                channel: channel.name
            };

            switch (channel.itemContentType) {
                case "PHOTO":
                    angular.element('#uploadImage').trigger('click');
                    break;
                case "SKETCH":
                    vm.showSketch = true;
                    initPad();
                    break;
                case "LOCATION":
                    getLocation();
                    break;

                case "DOCUMENT":
                    angular.element('#uploadDocument').trigger('click');
                    break;

                case "URL":
                    vm.showUrl = true;
                    break;

                case "VIDEO":
                    $rootScope.liveVideoActive ? stopLiveVideo() : startLiveVideo();
                    break;
            }
        }

        function startLiveVideo() {
            //Call the service to get me a token
            // console.log('Connecting to service to retrieve token');
            VideoInitiate.getVideoResource({
                sessionId: vm.session.id
            }).$promise.then(function (token) {
                roomToken = token[0];
                // console.log(roomToken); //Use returned token to connect me to a room as the client
                // console.log('Connecting to room as client');
                ConnectParticipant.connecting(roomToken); //Share my media channels to a dedicated div.
                $rootScope.liveVideoActive = true;
            }).then(function () {
                $scope.hasFinishedLoading = true;
            }).catch(function (error) {
                console.log('Error connecting to room', error);
            });
        }

        function stopLiveVideo() {
            // console.log('Preparing to stop live video');
            $rootScope.liveVideoActive = false;
            ConnectParticipant.disconnectRoom();
        }

        function getLocation() {
            vm.loading = true;
            Geolocation.getAccurateLocation({}).then(function (pos) {
                vm.newItem.content = {
                    latitude: pos.coords.latitude,
                    longitude: pos.coords.longitude,
                    accuracy: pos.coords.accuracy
                };
                vm.staticMapUrl = 'https://maps.googleapis.com/maps/api/staticmap?center=' + vm.newItem.content.latitude + ',' + vm.newItem.content.longitude + '&zoom=15&size=400x400&maptype=roadmap&markers=color:blue%7Clabel:S%7C' + vm.newItem.content.latitude + ',' + vm.newItem.content.longitude + '&key=' + GOOGLE_STATIC_MAPS_API;
                vm.loading = false;
                vm.showMap = true;
                vm.saving = true;
                saveItem();
            }).catch(function () {
                vm.loading = false;
                vm.locationSection = false;
                var newEvent = {
                    code: 301
                };
                Event.save({
                    sessionId: vm.session.id
                }, newEvent, function () {
                    $uibModal.open({
                        animation: true,
                        templateUrl: 'app/layouts/error/location.error.html',
                        controller: 'LocationErrorController',
                        controllerAs: 'vm',
                        backdrop: false,
                        keyboard: false
                    });
                }, errorCallback);
            });
        }

        function cleanView() {
            for (var i = 0; i < alertError.length; i++) {
                AlertService.closeAlert(alertError[i].id);
            }

            alertError = [];
            vm.newItem = {};
            vm.loading = false;
            vm.sending = false;
            vm.showUrl = false;
            angular.element('#uploadImage').val(null);
            angular.element('#uploadDocument').val(null);
        }

        var unsubscribe = $rootScope.$on('superChannelApp:sessionUpdate', function (event, result) {
            vm.session = result;
        });
        $scope.$on('$destroy', unsubscribe);
        $scope.$on('$destroy', function () {
            $interval.cancel(vm.updateInbox);
        });
    }
})();
