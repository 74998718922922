(function () {
    'use strict';

    angular
        .module('superChannelApp')
        .factory('JhiLanguageService', JhiLanguageService);

    JhiLanguageService.$inject = ['$q', '$http', 'LANGUAGES', '$window'];

    function JhiLanguageService($q, $http, LANGUAGES, $window) {
        var service = {
            getAll: getAll,
            getCurrent: getCurrent
        };

        return service;

        function getAll() {
            var deferred = $q.defer();
            deferred.resolve(LANGUAGES);
            return deferred.promise;
        }

        function getCurrent() {
            var deferred = $q.defer();
            var language = ($window.navigator.language || $window.navigator.userLanguage).split('-')[0];
            if (LANGUAGES.indexOf(language) === -1) {
                language = 'es';
            }
            deferred.resolve(language);

            return deferred.promise;
        }
    }
})();
