(function() {
    'use strict';

    angular
        .module('superChannelApp')
        .config(translationConfig);

    translationConfig.$inject = ['$translateProvider'];

    function translationConfig($translateProvider) {
        // Initialize angular-translate
        $translateProvider.useLoader('$translatePartialLoader', {
            urlTemplate: 'i18n/{lang}/{part}.json'
        });

        $translateProvider.preferredLanguage('en');
        $translateProvider.useSanitizeValueStrategy('escaped');
        $translateProvider.addInterpolation('$translateMessageFormatInterpolation');
    }
})();
